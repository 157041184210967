import {Box, CircularProgress, Dialog, DialogContent, DialogTitle, Stack, Tab, Tabs, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useSnackbarStore} from '@/global/store/SnackbarStore';
import {GroupHeader} from '../components/GroupPage/GroupHeader';
import GroupMainPage from '../components/GroupPage/GroupMainPage';
import AddStudentModal from '../components/GroupPage/AddStudentModal';
import StudentsGrid from '../components/GroupPage/StudentsGrid';
import ExamsGrid from '../components/GroupPage/ExamsGrid';
import PageHeader from '../../global/components/PageHeader';
import TeachersWindow from '../components/GroupPage/TeachersWindow';
import DriversWindow from '../components/GroupPage/DriversWindow';
import TransportWindow from '../components/GroupPage/TransportWindow';
import MastersWindow from '../components/GroupPage/MasterWindow';
import {wrapperSx} from '@/global/helpers/globalstyles';
import ClassesGrid from '../components/GroupPage/ClassesGrid';
import {useModal} from "@/global/providers/ModalProvider";
import {apiChangeStatusGroup, apiGroup} from "@/modules/groups/services/group.service";

function GroupPage() {

    const params = useParams();
    const navigate = useNavigate();

    const {t} = useTranslation();

    const {openModal} = useModal();

    const snackbar = useSnackbarStore((state) => state);
    const [selectedTab, setSelectedTab] = useState<string>('main');

    const {mutate: requestStartGroup} = apiChangeStatusGroup();

    const {data: groupInfo, isLoading: groupInfoLoading, error} = apiGroup(+(params.id ?? 0));

    useEffect(() => {
        if (!error) {
            return;
        }
        navigate('/groups');
    }, [error]);

    const tabs = {
        main: {id: 'main', label: t('school_page.group_page.main')},
        students: {id: 'students', label: `${t('school_page.group_page.students')} (${groupInfo?.students?.length})`},
        teachers: {
            id: 'teachers',
            label: `${t('school_page.group_page.teacher')} (${groupInfo?.teacher ? groupInfo.teacher.length : '0'})`
        },
        driver: {
            id: 'drivers',
            label: `${t('school_page.group_page.driver')} (${groupInfo?.driver ? groupInfo.driver.length : '0'})`
        },
        master: {
            id: 'masters',
            label: `${t('school_page.group_page.master')} (${groupInfo?.master ? groupInfo.master.length : '0'})`
        },
        transport: {
            id: 'transport',
            label: `${t('school_page.group_page.transport')} (${groupInfo?.transport ? groupInfo.transport.length : '0'})`
        },
        classes: {id: 'classes', label: `${t('school_page.group_page.classes')}`},
        exams: {id: 'exams', label: t('school_page.group_page.exams')},
    };

    const addStudentOpenModal = () => {
        if (!groupInfo) {
            return;
        }
        openModal(AddStudentModal, {groupInfo}, `add_student`);
    };

    const formGroup = () => {
        if (!groupInfo) {
            return;
        }

        const hasMaster = groupInfo.driver || groupInfo.master;

        if (groupInfo.students?.length && (groupInfo.students.length < 1 || groupInfo.students.length > 30 || !groupInfo.teacher || !hasMaster || !groupInfo.transport)) {
            snackbar.errorMessage(t('school_page.group_page.messages.create_group_denied'));
            return;
        }

        requestStartGroup({
            group_id: groupInfo.id,
            status: 2
        }, {
            onSuccess: () => {
                toast.success(t('school_page.group_page.messages.group_change_success'));
            }
        });
    };

    if (!groupInfo) {
        return (
            <Box
                className="App"
                sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
            >
                <PageHeader/>
                <Dialog
                    maxWidth={'xl'}
                    fullWidth={true}
                    open={groupInfoLoading}
                >
                    <DialogTitle>
                        Загрузка данных из АИС Автошкола
                    </DialogTitle>
                    <DialogContent>
                        <Stack
                            flexDirection={'column'}
                            gap={2}
                        >
                            <Stack
                                flexDirection={'row'}
                                justifyContent={'center'}
                            >
                                <Typography>
                                    Обновляются данные учебной группы
                                </Typography>
                            </Stack>
                            <Stack
                                flexDirection={'row'}
                                justifyContent={'center'}
                            >
                                <CircularProgress/>
                            </Stack>
                        </Stack>
                    </DialogContent>
                </Dialog>
            </Box>
        );
    }

    return (
        <Box
            className="App"
            sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
        >
            <PageHeader/>
            <Box sx={wrapperSx}>

                <GroupHeader
                    groupInfo={groupInfo}
                />
                <Tabs
                    value={selectedTab}
                    onChange={(e, newValue) => setSelectedTab(newValue)}
                    variant="scrollable"
                    scrollButtons={true}
                    sx={{mb: 2}}
                >
                    {Object.values(tabs).map(tab => (
                        <Tab
                            label={tab.label}
                            key={tab.id}
                            value={tab.id}
                        />
                    ))}
                </Tabs>

                {selectedTab === 'main' && (
                    <GroupMainPage
                        groupInfo={groupInfo}
                        addStudent={addStudentOpenModal}
                        formGroup={formGroup}
                    />
                )}

                {selectedTab === 'students' && (
                    <StudentsGrid
                        groupInfo={groupInfo}
                    />
                )}

                {selectedTab === 'teachers' && (
                    <TeachersWindow
                        groupInfo={groupInfo}
                    />
                )}

                {selectedTab === 'drivers' && (
                    <DriversWindow
                        groupInfo={groupInfo}
                    />
                )}

                {selectedTab === 'masters' && (
                    <MastersWindow
                        groupInfo={groupInfo}
                    />
                )}

                {selectedTab === 'transport' && (
                    <TransportWindow
                        groupInfo={groupInfo}
                    />
                )}

                {selectedTab === 'classes' && (
                    <ClassesGrid
                        groupInfo={groupInfo}
                    />
                )}

                {selectedTab === 'exams' && (
                    <ExamsGrid
                        groupInfo={groupInfo}
                    />
                )}

            </Box>
        </Box>
    );
}

export default GroupPage;