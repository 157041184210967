import { useEffect } from 'react';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { useSnackbarStore } from '@/global/store/SnackbarStore';
import { useUserStore } from '@/global/store/UserStore';
import { logoutFromSystem } from '@/global/api/api';
import { checkAuth } from '@/global/actions/main.api';
import { getName } from '@/global/helpers/text';
import { useSchoolStore } from '@/School/store/SchoolStore';
import SchoolsListComponent from '@/School/components/SchoolsListComponent';
import PageHeader from '@/global/components/PageHeader';
import { wrapperSx } from '@/global/helpers/globalstyles';

// TODO - разделить личные кабинеты администратора, менеджеров школ и студентов по разным страницам.

export function Main() {

    const navigate = useNavigate();

    const { t } = useTranslation();

    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const currentSchool = useSchoolStore((state) => state);

    const onIdle = () => {
        if (currentUser.iin) {
            navigate('/auth');
            snackbar.infoMessage(`Сессия была прекращена в связи с бездействием.`);
            logoutFromSystem();
        }
    };

    useIdleTimer({
        onIdle, timeout: 60000 * 15, throttle: 500
    });

    useEffect(() => {
        if (currentUser.isStudent) {
            navigate('/main/student');
            return;
        }

        if (currentUser.iin) {
            checkAuth()
                .then((res) => {
                    if (res?.data?.valid) {
                    } else {
                        currentUser.logOut();
                        snackbar.infoMessage('Прошло много времени с последнего посещения сайта. Необходимо авторизоваться повторно.');
                    }
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message
                        ? err.response.data.message
                        : 'Произошла ошибка обновления токена. Авторизуйтесь повторно');
                });

            if (!currentUser.isSuperAdmin && !currentUser.isAdmin && !currentUser.isObserver) {
                console.log(`ISN'T OBSERVER`);
                console.log(JSON.stringify(currentUser));
                navigate('/main/manager');
            }

        } else {
            navigate('/landing');
        }
    }, []);

    function GuestMenu() {
        return (
            <Stack
                direction={'column'}
            >
                <Button
                    sx={{ p: 2, mt: 2 }}
                    variant={'contained'}
                    onClick={() => {navigate('/auth');}}
                >
                    Войти в систему
                </Button>
                <Button
                    sx={{ p: 2, mt: 2 }}
                    variant={'contained'}
                    onClick={() => {navigate('/register');}}
                >
                    Зарегистрироваться
                </Button>
            </Stack>
        );
    }

    function ManagerMenu() {

        const name = getName(currentUser);

        return (
            <Stack
                direction={'column'}
                width={'100%'}
                sx={{ mt: '38px' }}
            >
                <Stack
                    flexDirection={'row'}
                    sx={{ mb: '38px' }}
                >
                    <Avatar
                        sx={{
                            width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'
                        }}
                    >
                        {name.partialName}
                    </Avatar>

                    <Stack
                        flexDirection={'column'}
                        justifyContent={'flex-start'}
                    >
                        <Typography sx={{ fontSize: '16px' }}>
                            {name.fullName}
                        </Typography>

                        <Typography
                            sx={{
                                mt: 'auto', fontSize: '12px'
                            }}
                        >
                            {t('register.kursant_study_grid.school')}
                            :
                            {currentSchool.title}
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }}>
                            <b>
                                ID:
                            </b>
                            {currentUser.id}
                        </Typography>
                    </Stack>

                    <Button
                        sx={{ p: 2, maxWidth: '250px', ml: 'auto' }}
                        variant={'outlined'}
                        onClick={() => {navigate(`/change-password/${currentUser.id}`);}}
                        color={'info'}
                    >
                        {t('register.user_page.change_password')}
                    </Button>

                </Stack>

                <SchoolsListComponent withoutSearch={true}/>
            </Stack>
        );
    }

    function AdminMenu() {
        const name = getName(currentUser);

        return (
            <Stack
                direction={'column'}
                width={'100%'}
                sx={{ mt: '38px', pb: '50px' }}
            >
                <Stack
                    flexDirection={'row'}
                    sx={{ mb: '16px' }}
                >
                    <Avatar
                        sx={{
                            width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'
                        }}
                    >
                        {name.partialName}
                    </Avatar>

                    <Stack
                        flexDirection={'column'}
                        justifyContent={'flex-start'}
                    >
                        <Typography sx={{ fontSize: '16px' }}>
                            {name.fullName}
                        </Typography>

                        <Typography sx={{ mt: 'auto', fontSize: '12px' }}>
                            Роль: Администратор
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }}>
                            <b>
                                ID:
                            </b>
                            {currentUser.id}
                        </Typography>
                    </Stack>

                    <Button
                        sx={{ p: 2, maxWidth: '250px', ml: 'auto' }}
                        variant={'outlined'}
                        onClick={() => {navigate(`/change-password/${currentUser.id}`);}}
                        color={'info'}
                    >
                        Сменить пароль
                    </Button>
                </Stack>

                <Stack
                    direction={'column'}
                    alignSelf={'center'}
                >
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            sx={{ mt: 3 }}
                            variant={'h5'}
                        >
                            <b>
                                Функции администратора
                            </b>
                        </Typography>
                    </Stack>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        variant={'contained'}
                        onClick={() => {navigate('/admin');}}
                    >
                        Перейти к заявкам на регистрацию
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        variant={'contained'}
                        onClick={() => {navigate('/schools');}}
                    >
                        Перейти к списку учебных организаций
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        variant={'contained'}
                        onClick={() => {navigate('/new-school');}}
                    >
                        {currentUser.isAdmin ? 'Добавить автошколу пользователю' : 'Добавить учебную организацию'}
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        variant={'contained'}
                        onClick={() => {navigate('/register');}}
                        color={'warning'}
                    >
                        Зарегистрировать автошколу и пользователя
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        variant={'contained'}
                        onClick={() => {navigate('/change-password');}}
                        color={'warning'}
                    >
                        Сменить пароль пользователю
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/tickets');}}
                    >
                        Перейти в службу поддержки
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/journal');}}
                    >
                        Журнал авторизаций
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/statistic');}}
                    >
                        Статистика по учебным организациям
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/notifications');}}
                    >
                        Создать уведомление
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/news-redactor');}}
                    >
                        Создать новость
                    </Button>
                    {currentUser.isSuperAdmin && (
                        <Stack
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Typography
                                sx={{ mt: 3 }}
                                variant={'h5'}
                            >
                                <b>
                                    Функции супер-администратора
                                </b>
                            </Typography>
                        </Stack>
                    )}
                    {currentUser.isSuperAdmin && (
                        <Button
                            sx={{ p: 2, mt: 2 }}
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => {
                                navigate('/admin/modules');
                            }}
                        >
                            Управление модулями системы
                        </Button>
                    )}
                </Stack>

            </Stack>
        );
    }

    function ObserverMenu() {
        const name = getName(currentUser);

        return (
            <Stack
                direction={'column'}
                width={'100%'}
                sx={{ mt: '38px', pb: '50px' }}
            >
                <Stack
                    flexDirection={'row'}
                    sx={{ mb: '16px' }}
                >
                    <Avatar
                        sx={{
                            width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'
                        }}
                    >
                        {name.partialName}
                    </Avatar>

                    <Stack
                        flexDirection={'column'}
                        justifyContent={'flex-start'}
                    >
                        <Typography sx={{ fontSize: '16px' }}>
                            {name.fullName}
                        </Typography>

                        <Typography sx={{ mt: 'auto', fontSize: '12px' }}>
                            Роль: Администратор
                        </Typography>
                        <Typography sx={{ fontSize: '12px' }}>
                            <b>
                                ID:
                                {' '}
                            </b>
                            {currentUser.id}
                        </Typography>
                    </Stack>

                    <Button
                        sx={{ p: 2, maxWidth: '250px', ml: 'auto' }}
                        variant={'outlined'}
                        onClick={() => {navigate(`/change-password/${currentUser.id}`);}}
                        color={'info'}
                    >
                        Сменить пароль
                    </Button>
                </Stack>

                <Stack
                    direction={'column'}
                    alignSelf={'center'}
                >
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            sx={{ mt: 3 }}
                            variant={'h5'}
                        >
                            <b>
                                Функции администратора
                            </b>
                        </Typography>
                    </Stack>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        variant={'contained'}
                        onClick={() => {navigate('/admin');}}
                    >
                        Перейти к заявкам на регистрацию
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        variant={'contained'}
                        onClick={() => {navigate('/schools');}}
                    >
                        Перейти к списку учебных организаций
                    </Button>
                    <Button
                        sx={{ p: 2, mt: 2 }}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/statistic');}}
                    >
                        Статистика по учебным организациям
                    </Button>
                </Stack>

            </Stack>
        );
    }

    return (
        <Box
            className="App"
            sx={{
                height: '100%', display: 'flex', flexDirection: 'column'
            }}
        >
            <PageHeader/>
            <Box sx={wrapperSx}>
                {!currentUser.iin && (
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            sx={{ mt: 3 }}
                            variant={'h5'}
                        >
                            <b>
                                Информационная система
                            </b>
                        </Typography>
                        <Typography variant={'h6'}>
                            Для учета групп учебной организации по подготовке водителей транспортных
                            средств Республики Казахстан
                        </Typography>
                    </Stack>
                )}

                {currentUser.iin && (
                    <Stack
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            sx={{ mt: 3 }}
                            variant={'h5'}
                        >
                            {t('register.app.welcome')}
                            {' '}
                            {currentUser.last_name}
                            {' '}
                            {currentUser.first_name}
                            !
                        </Typography>
                    </Stack>
                )}

                <Stack
                    direction={'column'}
                    sx={{ alignItems: 'center', mt: 5 }}
                >
                    {!currentUser.iin && (
                        <GuestMenu/>
                    )}
                    {currentUser.iin && currentUser.isAdmin && (
                        <AdminMenu/>
                    )}
                    {currentUser.iin && !currentUser.isAdmin && !currentUser.isSuperAdmin && !currentUser.isStudent && !currentUser.isObserver && (
                        <ManagerMenu/>
                    )}
                    {currentUser.iin && !currentUser.isAdmin && !currentUser.isSuperAdmin && !currentUser.isStudent && currentUser.isObserver && (
                        <ObserverMenu/>
                    )}
                </Stack>
            </Box>
        </Box>
    );
}

