import {useEffect, useState} from 'react';
import type {TextFieldProps} from "@mui/material/TextField/TextField";
import {validatePhone} from "@/global/helpers/validatePhone";
import {TextField} from "@mui/material";

const maskPhoneNumber = (phone: string) => {
    const x = phone
        .replace(/\D/g, '')
        .match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
    if (x) {
        return `+7 (${x[2] ? `${x[2]}` : ``}${x[3] ? `) ${x[3]}` : ``}${x[4] ? `-${x[4]}` : ``}${x[5] ? `-${x[5]}` : ``}`;
    } else {
        return `+7 (`;
    }
};

type Props =  {
    displayError?:boolean,
    onChange: (phone: string, isValid:boolean) => void;
} & Omit<TextFieldProps, 'onChange'>

export function PhoneInput({onChange, defaultValue, displayError, ...props}: Props) {
    const [phone, setPhone] = useState<string>(defaultValue?.toString() ?? '');
    const [error, setError] = useState<boolean | null>(null);
    const [helperText, setHelperText] = useState<string | null>(null);

    useEffect(() => {
        if (phone && phone?.length > 0) {
            validate(phone, true);
        }
    }, []);

    const validate = (rawString: string, displayError: boolean) => {
        const parsedPhone = validatePhone(rawString);

        if (!parsedPhone) {
            onChange(rawString, false);

            if (rawString.length <= 1) {
                setError(false);
                setHelperText(null);
            } else if (displayError) {
                setError(true);
                setHelperText('Некорректный номер');
            }

            return;
        }

        onChange(rawString, true);

        setError(null);
        setHelperText(null);
    };

    const handleChange = (values: string) => {
        const value = maskPhoneNumber(values);
        setPhone(value);
        validate(value, displayError ?? true);
    };

    return (
        <TextField
            variant={'outlined'}
            sx={{
                '& .MuiTextField-root': {
                    marginTop: 2
                }
            }}
            {...props}
            error={!!error}
            helperText={error ? helperText : ''}
            value={phone}
            onChange={(e) => handleChange(e.target.value)}
        />
    );
}
