import { useCustomQuery } from '@/global/hooks/useCustomQuery';
import { studentsService } from '@/global/api/gen';
import type {
    StudentsControllerGetSchoolStudentsQueryParams
} from '@/global/api/gen/ts/StudentsDTO/StudentsControllerGetSchoolStudents';
import { StudentKeys } from '@/modules/students/services/student.keys';
import type {
    StudentsControllerGetStudentInfoQueryParams
} from '@/global/api/gen/ts/StudentsDTO/StudentsControllerGetStudentInfo';
import { useCustomMutation } from '@/global/hooks/useCustomMutation';
import type { StudentDocSaveBodyDto } from '@/global/api/gen/ts/StudentDocSaveBodyDto';
import type {
    StudentsControllerFindStudentsByIINQueryParams
} from '@/global/api/gen/ts/StudentsDTO/StudentsControllerFindStudentsByIIN';
import type { OmmitedUseQueryOptions } from '@/global/types';
import type { StudentsSearchResponseDto } from '@/global/api/gen/ts/StudentsSearchResponseDto';
import type { StudentInfoAisResponseDto } from '@/global/api/gen/ts/StudentInfoAisResponseDto';
import type { StudentAddDto } from '@/global/api/gen/ts/StudentAddDto';
import type { StudentGradeDto } from '@/global/api/gen/ts/StudentGradeDto';
import type { StudentDeleteDto } from '@/global/api/gen/ts/StudentDeleteDto';
import type { StudentMedicalDto } from '@/global/api/gen/ts/StudentMedicalDto';
import type { StudentUserCreateDto } from '@/global/api/gen/ts/StudentUserCreateDto';
import type { StudentUserAttachDto } from '@/global/api/gen/ts/StudentUserAttachDto';

export const apiGetKursantList = (id: number, params: StudentsControllerGetSchoolStudentsQueryParams) => {
    return useCustomQuery({
        enabled: !!id,
        queryFn: () => studentsService.studentsControllerGetSchoolStudents({ id }, params)
            .then((res) => res.data),
        queryKey: StudentKeys.searchBySchool(id, params)
    });
};

export const apiGetKursantListByAdmin = (id: number, params: StudentsControllerGetSchoolStudentsQueryParams) => {
    return useCustomQuery({
        enabled: !!id,
        queryFn: () => studentsService.studentsControllerGetSchoolStudents({ id }, params)
            .then((res) => res.data),
        queryKey: StudentKeys.searchBySchool(id, params)
    });
};

export const apiGetKursantInfo = (studentId: number, params: StudentsControllerGetStudentInfoQueryParams) => {
    return useCustomQuery({
        enabled: !!studentId,
        queryFn: () => studentsService.studentsControllerGetStudentInfo({ studentId }, params)
            .then((res) => res.data),
        queryKey: StudentKeys.detailWithSchool(studentId, params)
    });
};

export const apiUpdateKursantDocs = () => {
    return useCustomMutation({
        mutationFn: ({ studentId, ...data }: {
            studentId: number
        } & StudentDocSaveBodyDto) => studentsService.studentsControllerSaveDocsInfoByStudentId({ studentId }, data)
            .then((res) => res.data),
        queryKey: (data) => StudentKeys.detailWithSchool(data.studentId, { schoolId: data.schoolId })
    });
};

export const apiSearchStudentByIin = (iin: string, params: StudentsControllerFindStudentsByIINQueryParams, option?: OmmitedUseQueryOptions<StudentsSearchResponseDto>) => {
    return useCustomQuery({
        enabled: !!iin,
        queryFn: () => studentsService.studentsControllerFindStudentsByIIN({ iin }, params)
            .then((res) => res.data),
        queryKey: StudentKeys.searchByIin(iin, params),
        ...option
    });
};

export const apiGetStudentsFromAis = (iin: string, options?: OmmitedUseQueryOptions<StudentInfoAisResponseDto>) => {
    return useCustomQuery({
        queryFn: () => studentsService.studentsControllerFindStudentsFromAisByIin({ iin })
            .then((res) => res.data),
        queryKey: StudentKeys.studentFromAis(iin),
        ...options
    });
};

export const apiGetStudentsFromAisForAdmin = (iin: string, options?: OmmitedUseQueryOptions<StudentInfoAisResponseDto>) => {
    return useCustomQuery({
        queryFn: () => studentsService.studentsControllerFindStudentsFromAisByIinHidden({ iin }).then(r => r.data),
        queryKey: StudentKeys.studentFromAisForAdmin(iin),
        ...options
    });
};

export const apiGetFeeStudent = (studentId: number) => {
    return useCustomQuery({
        enabled: !!studentId && !isNaN(studentId),
        queryFn: () => studentsService.studentsControllerGetFeeStudentInfo({ studentId })
            .then((res) => res.data),
        queryKey: StudentKeys.fee(studentId)
    });
};

export const apiAddStudent = () => {
    return useCustomMutation({
        mutationFn: (data: StudentAddDto) => studentsService.studentsControllerAddStudentToGroup(data)
            .then((res) => res.data),
        queryKey: () => StudentKeys.all
    });
};

export const apiSubmitGrade = () => {
    return useCustomMutation({
        mutationFn: (data: StudentGradeDto) => studentsService.studentsControllerSubmitGradeByStudentId({ studentId: data.student_id }, data)
            .then(res => res.data),
        queryKey: StudentKeys.all
    });
};

export const apiDeleteStudent = () => {
    return useCustomMutation({
        mutationFn: (data: StudentDeleteDto) => studentsService.studentsControllerDeleteStudentFromGroup(data)
            .then((res) => res.data),
        queryKey: StudentKeys.all
    });
};

export const apiCreateCorrectionStudent = () => {
    return useCustomMutation({
        mutationFn: (data: StudentMedicalDto) => studentsService.studentsControllerUpdateMedicalInfoByStudentId(data)
            .then((res) => res.data),
        queryKey: StudentKeys.all
    });
};

export const apiCreateUserForStudent = () => {
    return useCustomMutation({
        mutationFn: (data: StudentUserCreateDto) => studentsService.studentsControllerCreateUserForStudent(data)
            .then((res) => res.data),
        queryKey: StudentKeys.all
    });
};

export const apiAttachStudentToUser = () => {
    return useCustomMutation({
        mutationFn: (data: StudentUserAttachDto) => studentsService.studentsControllerAttachUserForStudent(data)
            .then((res) => res.data),
        queryKey: StudentKeys.all
    });
};

