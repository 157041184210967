import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";

export function FindStudentsBanner() {
    const navigate = useNavigate();

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            sx={{
                mt: '101px',
                px: {xs: '20px', lg: '70px'}
            }}
        >
            <Typography
                lineHeight={'98%'}
                fontSize={{xs:'36px', lg: '59px'}}
                sx={{
                    maxWidth: '824px',
                }}
                fontWeight={'bold'}
                color={'#05304F'}
                textAlign={{xs: 'center', lg: 'start'}}
            >
                Проверка данных обучения по ИИН
            </Typography>

            <Button
                variant={'contained'}
                color={'secondary'}
                sx={{
                    mt: 7,
                    width: '360px'
                }}
                onClick={() => {
                    navigate('/integration/find');
                }}
            >
                Перейти к проверке
            </Button>
        </Box>
    );
}