import {gridPageCountSelector, gridPaginationModelSelector, useGridApiContext, useGridSelector} from "@mui/x-data-grid";
import React from "react";
import {Pagination} from "@mui/material";
import {PaginationItem} from "@mui/lab";
import {PreviousIcon} from "@/global/icons/PreviousIcon";
import {NextIcon} from "@/global/icons/NextIcon";

export function CustomPagination() {
    const apiRef = useGridApiContext(); // Доступ к API DataGrid
    const pageCount = useGridSelector(apiRef, gridPageCountSelector); // Общее количество страниц

    const paginationModel = gridPaginationModelSelector(apiRef);
    const page = paginationModel.page;

    const rowCount = apiRef.current.getRowsCount();

    const handlePageChange = (_event: React.ChangeEvent<unknown>, value: number) => {
        apiRef.current.setPage(value - 1); // Обновляем текущую страницу
    };

    return (
        <Pagination
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1} // DataGrid использует 0-индексацию страниц, Pagination — 1-индексацию
            count={rowCount ? Math.round(rowCount / paginationModel.pageSize) : pageCount} // Количество страниц
            renderItem={(props) => {
                if (props.type === 'page' && props.selected) {
                    return (
                        <svg
                            width="16"
                            height="7"
                            viewBox="0 0 16 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                width="15.36"
                                height="6.4"
                                rx="3.2"
                                fill="#05304F"
                            />
                        </svg>
                    );
                } else if (props.type === 'page' && !props.selected) {
                    return (
                        (
                            <svg
                                width="7"
                                height="7"
                                viewBox="0 0 7 7"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    x="0.200195"
                                    width="6.4"
                                    height="6.4"
                                    rx="3.2"
                                    fill="#05304F"
                                    fill-opacity="0.2"
                                />
                            </svg>
                        )
                    );
                } else {
                    return (
                        <PaginationItem
                            {...props}
                            slots={{previous: PreviousIcon, next: NextIcon}}
                            sx={{
                                width: 'fit-content',
                                height: 'fit-content',
                                border: '0px',
                                p: 0,
                                borderRadius: '16px'
                            }}
                        />
                    );
                }
            }}
            sx={{
                display: "flex",
                gap: '16px',
                '.MuiPagination-ul': {
                    display: 'flex',
                    flexDirection: 'row',
                    '> li:first-of-type': {
                        marginLeft: '40px',
                        marginRight: '83px', // Применяем отступ к первому <li>
                    },
                    '> li:last-of-type': {
                        marginLeft: '83px', // Применяем отступ к последнему <li>
                    },
                }
            }}
            onChange={handlePageChange}
        />
    );
}