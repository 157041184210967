import type {
	SchoolsControllerSearchSchoolsQueryParams
} from '@/global/api/gen/ts/schoolsDTO/SchoolsControllerSearchSchools';
import type {
	GroupsControllerGetPracticeHoursInfoByGroupIdQueryParams
} from "@/global/api/gen/ts/GroupsDTO/GroupsControllerGetPracticeHoursInfoByGroupId";
import type {
	GroupsControllerGetSectionsInfoByGroupIdQueryParams
} from "@/global/api/gen/ts/GroupsDTO/GroupsControllerGetSectionsInfoByGroupId";
import type {
	GroupsControllerGetGroupsWithStatusExamsByUserIdQueryParams
} from "@/global/api/gen/ts/GroupsDTO/GroupsControllerGetGroupsWithStatusExamsByUserId";
import type {
	GroupsControllerGetGroupClassesQueryParams
} from "@/global/api/gen/ts/GroupsDTO/GroupsControllerGetGroupClasses";

export const GroupKeys = {
	all: ['groups'] as const,
	search: ['groups', 'search'] as const,
	detail: (id: number) => [...GroupKeys.all, 'detail', `${id}`] as const,
	classes: (id: number) => [...GroupKeys.all, 'classes', `${id}`] as const,
	classesSearch: (id:number, params: GroupsControllerGetGroupClassesQueryParams) => [...GroupKeys.classes(id), 'search', params] as const,
	searchBySchool: (id: number) => [...GroupKeys.all, `${id}`] as const,
	schoolPublic: (id:number,params: SchoolsControllerSearchSchoolsQueryParams) => [...GroupKeys.searchBySchool(id), params] as const,
	hours: (id:number) => [...GroupKeys.all, 'hours', `${id}`] as const,
	practiceHours: (id:number, params: GroupsControllerGetPracticeHoursInfoByGroupIdQueryParams)=> [...GroupKeys.hours(id), 'practice', params] as const,
	theoryHours: (id:number, params:GroupsControllerGetSectionsInfoByGroupIdQueryParams)=> [...GroupKeys.hours(id), 'theory', params] as const,
	groupExams: (params: GroupsControllerGetGroupsWithStatusExamsByUserIdQueryParams) => [...GroupKeys.all, 'exams', params] as const,
};