import React, { type ReactElement } from 'react';

import AuthPage from '../../Auth/pages/AuthPage';
import RegisterPage from '../../Auth/pages/RegisterPage';
import RegisterAsStudentPage from '../../Auth/pages/RegisterAsStudentPage';
import StudentMainPage from '../../Main/pages/StudentMainPage';
import ManagerMainPage from '../../Main/pages/ManagerMainPage';
import FeePage from '../../Payments/pages/FeePage';
import QuickFeePage from '../../Payments/pages/QuickFeePage';
import QuickFeePaymentPage from '../../Payments/pages/QuickFeePaymentPage';
import AdminPage from '../../AdminPanel/pages/AdminPage';
import AdminNewsRedactor from '../../AdminPanel/pages/AdminNewsRedactor';
import SuperAdminModulesPage from '../../AdminPanel/pages/SuperAdminModulesPage';
import AllNotificationsPage from '../../Notifications/pages/AllNotificationsPage';
import NewsPage from '../../News/pages/NewsPage';
import SchoolsList from '../../School/pages/SchoolsList';
import SchoolPage from '../../School/pages/SchoolPage';
import ChangeSchoolAdmin from '../../School/pages/ChangeSchoolAdmin';
import GroupsList from '../../Group/pages/GroupsList';
import GroupPage from '../../Group/pages/GroupPage';
import EmployeesPage from '../../Employees/pages/EmployeesPage';
import EmployeeEditInfo from '../../Employees/pages/EmployeeEditInfo';
import TransportPage from '../../Transport/pages/TransportPage';
import SchoolWorkers from '../../School/pages/SchoolWorkers';
import SchoolKursants from '../../School/pages/SchoolKursants';
import SchoolKursantPage from '../../School/pages/SchoolKursantPage';
import KursantDocsPDF from '../../School/pages/KursantDocsPDF';
import ConfirmPage from '../../Confirm/pages/ConfirmPage';
import TicketsAdminPage from '../../Tickets/pages/TicketsAdminPage';
import TicketsUserPage from '../../Tickets/pages/TicketsUserPage';
import NewTicketPage from '../../Tickets/pages/NewTicketPage';
import TicketMessages from '../../Tickets/pages/TicketMessages';
import { JournalPage } from '@/Journal/pages/JournalPage';
import BasePage from '../../Base/pages/BasePage';
import SchoolTransactions from '../../School/pages/SchoolTransactions';
import SchoolTopUpPage from '../../School/pages/SchoolTopUpPage';
import NewSchoolAdmin from '../../School/pages/NewSchoolAdmin';
import UserPage from '../../Users/pages/UserPage';
import ChangePasswordList from '../../Users/pages/ChangePasswordList';
import ChangeUserPassword from '../../Users/pages/ChangeUserPassword';
import ChangeUserInfo from '../../Users/pages/ChangeUserInfo';
import StatisticsPage from '../../Statistic/pages/StatisticsPage';
import GroupEnrollment from '../../Group/pages/GroupEnrollment';
import GroupExamsProtocol from '../../Group/pages/GroupExamsProtocol';
import GroupGraduation from '../../Group/pages/GroupGraduation';
import CertificateCheckPage from '../../Group/pages/CertificateCheckPage';
import StudentCertificate from '../../Group/pages/StudentCertificate';
import StudentCertificateA4 from '../../Group/pages/StudentCertificateA4';
import QuestionEditForm from '../../Test/Question/pages/QuestionEditForm';
import QuestionOverview from '../../Test/Question/pages/QuestionOverview';
import BuyingPage from '../../Payments/pages/BuyingPage';
import TransactionPage from '../../Payments/pages/TransactionPage';
import QuickTransactionPage from '../../Payments/pages/QuickTransactionPage';
import SchoolLandingPage from '../../Landing/pages/SchoolLandingPage';
import GovPage from '../../GovPage/pages/GovPage';
import SchoolCabinetsPage from '../../Cabinets/pages/SchoolCabinetsPage';
import SchoolChangeInfoPage from '../../School/pages/SchoolChangeInfoPage';
import TheoryExamPage from '../../TheoryExam/pages/TheoryExamPage';
import TheoryExamResultsPage from '../../TheoryExam/pages/TheoryExamResultsPage';
import TrialExamPage from '../../TheoryExam/pages/TrialExamPage';
import TrialExamResultsPage from '../../TheoryExam/pages/TrialExamResultsPage';
import StudentsByIinPage from '../../Landing/pages/StudentsByIinPage';
import { AdminCreateNotification } from '@/Notifications/pages/AdminCreateNotification';
import { QuestionsList } from '@/Test/Question/pages/QuestionsList';
import { Main } from '@/Main/pages/Main';
import { HiddenStudentsByIinPage } from '@/Landing/pages/HiddenStudentsByIinPage';

export type Keys = 'all' | 'admin' | 'student' | 'excludeStudent' | 'superadmin';

export enum TypeKeys {
    'all',
    'admin',
    'superadmin',
    'student',
    'excludeStudent'
}

const Roles: Record<Keys, TypeKeys> = {
    all: TypeKeys.all,
    admin: TypeKeys.admin,
    student: TypeKeys.student,
    superadmin: TypeKeys.superadmin,
    excludeStudent: TypeKeys.excludeStudent
};

export interface RouteInterface {
    path: string,
    element: ReactElement,
    isPublic?: boolean,
    role: TypeKeys,
    redirect?: string,
}

export const RoutesElement: RouteInterface[] = [
    {
        path: '/', element: <Main/>, isPublic: true, role: Roles.all
    }, {
        path: '/auth', element: <AuthPage/>, isPublic: true, role: Roles.all, redirect: '/'
    }, {
        path: '/register', element: <RegisterPage/>, isPublic: true, role: Roles.all, redirect: '/'
    }, {
        path: '/register/student', element: <RegisterAsStudentPage/>, isPublic: true, role: Roles.all
    }, {
        path: '/main/student', element: <StudentMainPage/>, role: Roles.all
    }, {
        path: '/main/manager', element: <ManagerMainPage/>, role: Roles.all
    }, {
        path: '/fee/:studentId', element: <FeePage/>, role: Roles.all
    }, {
        path: '/quick-fee/', element: <QuickFeePage/>, role: Roles.all
    }, {
        path: '/quick-fee/:studentId', element: <QuickFeePaymentPage/>, role: Roles.all
    }, {
        path: '/admin', element: <AdminPage/>, role: Roles.excludeStudent
    }, {
        path: '/admin/notifications', element: <AdminCreateNotification/>, role: Roles.excludeStudent
    }, {
        path: '/admin/news-redactor', element: <AdminNewsRedactor/>, role: Roles.excludeStudent
    }, {
        path: '/admin/modules', element: <SuperAdminModulesPage/>, role: Roles.superadmin
    }, {
        path: '/notifications', element: <AllNotificationsPage/>, role: Roles.all
    }, {
        path: '/news/:newsId', element: <NewsPage/>, role: Roles.all
    }, {
        path: '/schools', element: <SchoolsList/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id', element: <SchoolPage/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/change-by-admin', element: <ChangeSchoolAdmin/>, role: Roles.excludeStudent
    }, {
        path: '/groups', element: <GroupsList/>, role: Roles.excludeStudent
    }, {
        path: '/groups/:id', element: <GroupPage/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/teachers', element: <EmployeesPage employee={'teachers'}/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/drivers', element: <EmployeesPage employee={'drivers'}/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/masters', element: <EmployeesPage employee={'masters'}/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/employees/:employeeId', element: <EmployeeEditInfo/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/transport', element: <TransportPage/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/workers', element: <SchoolWorkers/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/kursants', element: <SchoolKursants/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/kursants/:kursantId', element: <SchoolKursantPage/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/kursants/:kursantId/:type', element: <KursantDocsPDF/>, role: Roles.excludeStudent
    }, {
        path: '/confirm/:id', element: <ConfirmPage/>, role: Roles.all
    }, {
        path: '/admin/tickets', element: <TicketsAdminPage/>, role: Roles.excludeStudent
    }, {
        path: '/tickets', element: <TicketsUserPage/>, role: Roles.all
    }, {
        path: '/tickets/new', element: <NewTicketPage/>, role: Roles.all
    }, {
        path: '/tickets/:id', element: <TicketMessages/>, role: Roles.all
    }, {
        path: '/journal', element: <JournalPage/>, role: Roles.all
    }, {
        path: '/schools/:id/base', element: <BasePage/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/transactions', element: <SchoolTransactions/>, role: Roles.excludeStudent
    }, {
        path: '/schools/:id/topup', element: <SchoolTopUpPage/>, role: Roles.excludeStudent
    }, {
        path: '/new-school', element: <NewSchoolAdmin/>, role: Roles.admin
    }, {
        path: '/user/:id', element: <UserPage/>, role: Roles.excludeStudent
    }, {
        path: '/change-password', element: <ChangePasswordList/>, role: Roles.admin
    }, {
        path: '/change-password/:id', element: <ChangeUserPassword/>, role: Roles.all
    }, {
        path: '/change-userinfo/:id', element: <ChangeUserInfo/>, role: Roles.all
    }, {
        path: '/statistic', element: <StatisticsPage/>, role: Roles.excludeStudent
    }, {
        path: '/enrollment/:id', element: <GroupEnrollment/>, role: Roles.all
    }, {
        path: '/exams-protocol/:id', element: <GroupExamsProtocol/>, role: Roles.all
    }, {
        path: '/graduation/:id', element: <GroupGraduation/>, role: Roles.all
    }, {
        path: '/certificate/:id', element: <CertificateCheckPage/>, role: Roles.all
    }, {
        path: '/certificate/pdf/:id', element: <StudentCertificate/>, role: Roles.all
    }, {
        path: '/certificate/A4/pdf/:id', element: <StudentCertificateA4/>, role: Roles.all
    }, {
        path: '/questions', element: <QuestionsList/>, role: Roles.all
    }, {
        path: '/questions/new', element: <QuestionEditForm/>, role: Roles.all
    }, {
        path: '/questions/:id', element: <QuestionEditForm/>, role: Roles.all
    }, {
        path: '/questions/demo', element: <QuestionOverview/>, role: Roles.all
    }, {
        path: '/questions/demo/:id', element: <QuestionOverview/>, role: Roles.all
    }, {
        path: '/purchase', element: <BuyingPage/>, role: Roles.all
    }, {
        path: '/transactions/:transactionId', element: <TransactionPage/>, role: Roles.all
    }, {
        path: '/quick-transactions/:transactionId', element: <QuickTransactionPage/>, role: Roles.all
    }, {
        path: '/landing', element: <SchoolLandingPage/>, isPublic: true, role: Roles.all
    }, {
        path: '/gov', element: <GovPage/>, isPublic: true, role: Roles.all
    }, {
        path: '/schools/:id/cabinets', element: <SchoolCabinetsPage/>, role: Roles.all
    }, {
        path: '/schools/:id/change-info', element: <SchoolChangeInfoPage/>, role: Roles.all
    }, {
        path: '/theory-exams/:id', element: <TheoryExamPage/>, role: Roles.all
    }, {
        path: '/theory-exams/results/:id', element: <TheoryExamResultsPage/>, role: Roles.all
    }, {
        path: '/trial-exams/:id', element: <TrialExamPage/>, role: Roles.all
    }, {
        path: '/trial-exams/results/:id', element: <TrialExamResultsPage/>, role: Roles.all
    }, {
        path: '/integration/find', element: <StudentsByIinPage/>, isPublic: true, role: Roles.all
    }, {
        path: '/internal_iin_check_3910', element: <HiddenStudentsByIinPage/>, isPublic: true, role: Roles.all
    }
];

