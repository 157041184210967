import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  CabinetsControllerCreateCabinetMutationRequest,
  CabinetsControllerCreateCabinetMutationResponse,
} from '../../ts/CabinetsDTO/CabinetsControllerCreateCabinet'

export function getCabinetsControllerCreateCabinetUrl() {
  return `/cabinets` as const
}

/**
 * @summary Добавить помещение
 * {@link /cabinets}
 */
export async function cabinetsControllerCreateCabinet(
  data: CabinetsControllerCreateCabinetMutationRequest,
  config: Partial<RequestConfig<CabinetsControllerCreateCabinetMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const formData = new FormData()
  if (data) {
    Object.keys(data).forEach((key) => {
      const value = data[key as keyof typeof data]
      if (typeof key === 'string' && (typeof value === 'string' || value instanceof Blob)) {
        formData.append(key, value)
      }
    })
  }
  const res = await request<CabinetsControllerCreateCabinetMutationResponse, ResponseErrorConfig<Error>, CabinetsControllerCreateCabinetMutationRequest>({
    method: 'POST',
    url: getCabinetsControllerCreateCabinetUrl().toString(),
    data: formData,
    ...requestConfig,
    headers: { 'Content-Type': 'multipart/form-data', ...requestConfig.headers },
  })
  return res
}