import React from 'react';
import {Box, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import PageHeader from '../../global/components/PageHeader';
import {wrapperSx} from '@/global/helpers/globalstyles';
import {useUserStore} from '@/global/store/UserStore';
import {TicketsSection} from "@/modules/tickets/components/TicketsSection";
import {UserInfo} from "@/modules/students/components/UserInfo";
import {ManagerMainSchoolInfo} from "@/modules/students/components/ManagerMainSchoolInfo";
import {NewsSection} from "@/modules/news/components/NewsSection";

function ManagerMainPage() {

    const currentUser = useUserStore((state) => state);

    const {t} = useTranslation();

    return (
        <Box
            className="main-page"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <PageHeader/>
            <Box sx={{...wrapperSx, px: {xs:'10px', lg:'60px'}, pb: '60px'}}>
                <Typography
                    fontSize={'24px'}
                    lineHeight={'120%'}
                    color={'#707EAE'}
                    sx={{
                        mt: '20px'
                    }}
                >
                    {t('main-pages.manager.hello')}
                    {' '}
                    {currentUser.first_name}
                </Typography>
                <Typography
                    fontSize={{xs:'32px', lg: '40px'}}
                    lineHeight={'100%'}
                    color={'#2b3674'}
                    fontWeight={'bold'}
                    sx={{
                        mt: '11px'
                    }}
                >
                    {t('main-pages.manager.welcome')}
                </Typography>

                <Stack
                    flexDirection={{xs:'column', lg: 'row'}}
                    alignItems={{xs:'center', lg: 'flex-start'}}
                    sx={{
                        mt: '46px'
                    }}
                    gap={'20px'}
                >
                    <UserInfo />

                    <ManagerMainSchoolInfo/>

                </Stack>

                <TicketsSection/>

                <NewsSection
                    authorized={true}
                />
            </Box>
        </Box>
    );
}

export default ManagerMainPage;