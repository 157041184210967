import type { GridColDef, GridRowParams } from '@mui/x-data-grid';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, CircularProgress, type Theme } from '@mui/material';
import { DirectionsCar } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { SchoolCard } from '@/modules/schools/components/SchoolCard';
import { ServerDataGrid } from '@/global/components/ServerDataGrid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { apiGetSchoolPublic } from '@/modules/schools/services/school.service';
import type { SchoolDto } from '@/global/api/gen/ts/SchoolDto';

interface Props {
	onRowClick: (id:number| GridRowParams) => void;
	filter:string
}

export function PublicSchoolTable({onRowClick,filter}:Props) {
	const { t } = useTranslation();

	const isMobile = useMediaQuery((theme:Theme)=> theme.breakpoints.down('md'));

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25, page: 0
	});

	const {data, isLoading} = apiGetSchoolPublic({
		offset: paginationModel.page * paginationModel.pageSize,
		limit: paginationModel.pageSize,
		q:filter
	});

	const columns: GridColDef<SchoolDto>[] = useMemo(() => [
		{
			field: 'empty',
			headerName: '',
			headerClassName: 'datagrid--header',
			width: 46,
			sortable: false,
		},
		{
			field: 'id',
			headerName: 'ID',
			headerClassName: 'datagrid--header',
			width: 90,
			sortable: true,
		},
		{
			field: 'bin',
			headerName: t('register.school_list_component.grid.bin'),
			headerClassName: 'datagrid--header',
			width: 200,
			sortable: false,
		},
		{
			field: 'title',
			headerName: t('register.school_list_component.grid.title'),
			headerClassName: 'datagrid--header',
			width: 500,
			sortable: false,
			renderCell: (params) => {
				return (
					<React.Fragment>
						<Link
							to={`/schools/${params.row.id}`}
							style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
						>
							<Avatar
								sx={{color: '#1E9AF3', bgcolor: '#fff', mr: 2}}
							>
								<DirectionsCar fontSize="small"/>
							</Avatar>
							{params.value}
						</Link>
					</React.Fragment>
				);
			}
		}
	], []);

	if (isMobile) {
		return (
			<>
				{(isLoading) && <CircularProgress />}
				{
					data && data.items.map((row)=> (
						<SchoolCard
							onRowClick={onRowClick}
							key={row.id}
							school={row}
						/>
					))
				}
			</>
		);
	}

	return (
		<ServerDataGrid
			paginationModel={paginationModel}
			onPaginationModelChange={setPaginationModel}
			rows={data?.items ?? []}
			onRowClick={onRowClick}
			columns={columns}
			loading={isLoading}
			rowCount={data?.total ?? 0}
			disableRowSelectionOnClick
			disableColumnMenu={true}
			disableColumnFilter={true}
		/>
	);
}