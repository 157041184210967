import { useCustomQuery } from '@/global/hooks/useCustomQuery';
import { transportService } from '@/global/api/gen';
import type {
	TransportControllerGetAllSchoolTransportsQueryParams
} from '@/global/api/gen/ts/TransportDTO/TransportControllerGetAllSchoolTransports';
import { TransportKeys } from '@/modules/transports/services/transport.keys';
import { useCustomMutation } from '@/global/hooks/useCustomMutation';
import type {
	TransportControllerCreateTransportMutationRequest
} from '@/global/api/gen/ts/TransportDTO/TransportControllerCreateTransport';

export const apiGetTransportListBySchool = (id:number , params: TransportControllerGetAllSchoolTransportsQueryParams) => {
	return useCustomQuery({
		enabled: !!id && !isNaN(id),
		queryFn: () => transportService.transportControllerGetAllSchoolTransports({id}, params)
			.then((res) => res.data),
		queryKey: TransportKeys.searchBySchool(id, params)
	});
};

export const apiCreateTransport = () => {
	return useCustomMutation({
		mutationFn: (data: TransportControllerCreateTransportMutationRequest) => transportService.transportControllerCreateTransport(data)
			.then((res) => res.data),
		queryKey: (data) => TransportKeys.bySchool(data.school_id)
	});
};