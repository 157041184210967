import { employeeControllerChangeEmployeeInfo } from './employeeControllerChangeEmployeeInfo'
import { employeeControllerCreateDriver } from './employeeControllerCreateDriver'
import { employeeControllerCreateMaster } from './employeeControllerCreateMaster'
import { employeeControllerCreateTeacher } from './employeeControllerCreateTeacher'
import { employeeControllerFireDriverById } from './employeeControllerFireDriverById'
import { employeeControllerFireMasterById } from './employeeControllerFireMasterById'
import { employeeControllerFireTeacherById } from './employeeControllerFireTeacherById'
import { employeeControllerGetActiveSchoolDrivers } from './employeeControllerGetActiveSchoolDrivers'
import { employeeControllerGetActiveSchoolMasters } from './employeeControllerGetActiveSchoolMasters'
import { employeeControllerGetActiveSchoolTeachers } from './employeeControllerGetActiveSchoolTeachers'
import { employeeControllerGetAllSchoolDrivers } from './employeeControllerGetAllSchoolDrivers'
import { employeeControllerGetAllSchoolMasters } from './employeeControllerGetAllSchoolMasters'
import { employeeControllerGetAllSchoolTeachers } from './employeeControllerGetAllSchoolTeachers'
import { employeeControllerGetDriverById } from './employeeControllerGetDriverById'
import { employeeControllerGetEmployeeInfo } from './employeeControllerGetEmployeeInfo'
import { employeeControllerGetInActiveSchoolDrivers } from './employeeControllerGetInActiveSchoolDrivers'
import { employeeControllerGetInActiveSchoolMaster } from './employeeControllerGetInActiveSchoolMaster'
import { employeeControllerGetInActiveSchoolTeachers } from './employeeControllerGetInActiveSchoolTeachers'
import { employeeControllerGetTeacherById } from './employeeControllerGetTeacherById'

export const employeesService = {
    employeeControllerGetEmployeeInfo,
    employeeControllerCreateTeacher,
    employeeControllerCreateDriver,
    employeeControllerCreateMaster,
    employeeControllerGetAllSchoolTeachers,
    employeeControllerGetAllSchoolDrivers,
    employeeControllerGetAllSchoolMasters,
    employeeControllerGetActiveSchoolTeachers,
    employeeControllerGetActiveSchoolDrivers,
    employeeControllerGetActiveSchoolMasters,
    employeeControllerGetInActiveSchoolTeachers,
    employeeControllerGetInActiveSchoolDrivers,
    employeeControllerGetInActiveSchoolMaster,
    employeeControllerGetTeacherById,
    employeeControllerGetDriverById,
    employeeControllerFireTeacherById,
    employeeControllerFireDriverById,
    employeeControllerFireMasterById,
    employeeControllerChangeEmployeeInfo,
  }