import client from '../../../api'
import type { RequestConfig, ResponseErrorConfig } from '../../../api'
import type {
  EmployeeControllerChangeEmployeeInfoMutationRequest,
  EmployeeControllerChangeEmployeeInfoMutationResponse,
} from '../../ts/employeesDTO/EmployeeControllerChangeEmployeeInfo'

export function getEmployeeControllerChangeEmployeeInfoUrl() {
  return `/employees/change` as const
}

/**
 * @summary Изменение информации о сотруднике
 * {@link /employees/change}
 */
export async function employeeControllerChangeEmployeeInfo(
  data: EmployeeControllerChangeEmployeeInfoMutationRequest,
  config: Partial<RequestConfig<EmployeeControllerChangeEmployeeInfoMutationRequest>> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config

  const res = await request<
    EmployeeControllerChangeEmployeeInfoMutationResponse,
    ResponseErrorConfig<Error>,
    EmployeeControllerChangeEmployeeInfoMutationRequest
  >({ method: 'POST', url: getEmployeeControllerChangeEmployeeInfoUrl().toString(), data, ...requestConfig })
  return res
}