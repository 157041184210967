import React, { useCallback, useState } from 'react';
import { useUserStore } from '@/global/store/UserStore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { GridRowParams } from '@mui/x-data-grid';
import { Box, InputBase, Stack, Typography } from '@mui/material';
import { AdminSchoolTable } from '@/modules/schools/tables/AdminSchoolTable';
import { PublicSchoolTable } from '@/modules/schools/tables/PublicSchoolTable';

interface SchoolsListComponentProps {
    withoutSearch?: boolean
}

function SchoolsListComponent({withoutSearch}: SchoolsListComponentProps) {
	const isAdmin = useUserStore((state) => state.isAdmin);

	const { t } = useTranslation();
	const [filter, setFilter] = useState<string>('');

	const navigate = useNavigate();

	const onRowClick = useCallback((id:GridRowParams | number) => {
		if (typeof id === 'number') {
			navigate(`/schools/${id}`);
		}
		else {
			navigate(`/schools/${id.id}`);
		}
	},[]);

	return (
		<Box>
			<Stack
				flexDirection={{md: 'row', xs: 'column'}}
				flexWrap="wrap"
				sx={{mb: 2}}
			>
				{!withoutSearch && (
					<Stack
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Typography
							sx={{mt: 3}}
							variant={'h5'}
						>
							<b>
								{t('school_page.employees_page.schools_list_page')}
							</b>
						</Typography>
					</Stack>
				)}
			</Stack>
			{!withoutSearch && (
				<InputBase
					type={'text'}
					onChange={(e) => {
						setFilter(e.target.value);
					}}
					placeholder={'Поиск'}
					sx={{mb: 1}}
					value={filter}
				/>
			)}
			{
				isAdmin ? (
					<AdminSchoolTable
						filter={filter}
						onRowClick={onRowClick}
					/>
				) : (
					<PublicSchoolTable
						filter={filter}
						onRowClick={onRowClick}
					/>
				)
			}
		</Box>
	);
}

export default SchoolsListComponent;
