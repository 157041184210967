import {useCustomQuery} from "@/global/hooks/useCustomQuery";
import {certificateService} from "@/global/api/gen";
import {CertificatesKeys} from "@/modules/certificates/services/certificates.keys";
import {useCustomMutation} from "@/global/hooks/useCustomMutation";

export const apiGetCertificateCheck = (studentId: string) => {
    return useCustomQuery({
        enabled: !!studentId,
        queryFn: () => certificateService.certificateControllerCheckCertificate({studentId})
            .then((res) => res.data),
        queryKey: CertificatesKeys.check(studentId),
    });
};

export const apiCreateCertificates = () => {
    return useCustomMutation({
        mutationFn: (data: {id:number}) => certificateService.certificateControllerChangeAppointment(data)
            .then((res)=> res.data),
        queryKey: CertificatesKeys.all
    });
};