import {Box, Button, Stack, type Theme, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

export function MainBanner() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    return (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                minHeight: '813px',
                backgroundImage: `url(/images/new-design/banner-bg.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                position: 'relative',
                borderRadius: '30px',
                overflow: 'hidden',
                py: {xs: '20px', lg: '0'},
                pb: {xs: '75px', lg: '0'}
            }}
        >
            <Stack
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                    zIndex: 2,
                    py: {xs: '0', lg: '28px'},
                    px: {xs: '20px', lg: '50px'}
                }}
                className={'header'}
                gap={{xs: '16px', lg: '0'}}
            >
                <img
                    src={'/images/new-design/avtomektep-logo2.png'}
                    style={{
                        width: '196',
                        height: '35px'
                    }}
                    alt={'logo'}
                />
            </Stack>

            <Stack
                flexDirection={{xs: 'column', lg: 'row'}}
                justifyContent={'space-between'}
                sx={{
                    mt: {xs: '50px', lg: '0'},
                    px: {xs: '20px', lg: '50px'}
                }}
                className={'content'}
            >
                <Stack
                    flexDirection={'column'}
                    gap={{xs: '16px', lg: '50px'}}
                    sx={{
                        maxWidth: '700px',
                    }}
                >
                    <Typography
                        fontSize={{xs: '35px', lg: '59px'}}
                        color={'#fff'}
                        lineHeight={'98%'}
                        fontWeight={'bold'}
                    >
                        {t('landing.schools-landing.title')}
                    </Typography>
                    <Typography
                        fontSize={{xs: '15px', lg: '17px'}}
                        color={'#fff'}
                        lineHeight={'150%'}
                        sx={{
                            mt: {xs: '34px', lg: '0'}
                        }}
                    >
                        {t('landing.schools-landing.about-system-content')}
                    </Typography>
                </Stack>

                {!isMobile && (
                    <Stack
                        flexDirection={'column'}
                    >
                        <Stack
                            flexDirection={'row'}
                            sx={{
                                // backgroundColor: 'rgba(255, 255, 255, 0.14)',
                                width: '464px',
                                height: '220px',
                                borderRadius: '16px',
                                zIndex: 4,
                                p: '30px',
                                mt: {xs: '16px', lg: '0px'},
                                position: 'relative'
                            }}
                        >
                            <img
                                src={'/images/new-design/cloud.png'}
                                alt={''}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    userSelect: 'none',
                                    zIndex: -1
                                }}
                            />
                            <Stack
                                flexDirection={'column'}
                                sx={{
                                    mr: {xs: '16px', lg: '42px'},
                                }}
                            >
                                <img
                                    src={'/images/new-design/quotation.png'}
                                    alt={'Цитата'}
                                    style={{
                                        width: '38px',
                                        height: '32px',
                                    }}
                                />
                            </Stack>

                            <Stack
                                flexDirection={'column'}
                                sx={{
                                    width: {xs: 'auto', lg: '303px'},
                                    height: {xs: 'auto', lg: '140px'}
                                }}
                            >
                                <Typography
                                    fontSize={'17px'}
                                    color={'#fff'}
                                    lineHeight={'117%'}
                                >
                                    {t('landing.schools-landing.tokaev-message')}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack
                            flexDirection={'row'}
                            sx={{
                                mt: '20px'
                            }}
                            alignSelf={'flex-end'}
                            gap={'13px'}
                        >
                            <Stack
                                flexDirection={'column'}
                                justifyContent={'center'}
                                gap={'7px'}
                            >
                                <Typography
                                    lineHeight={'98%'}
                                    fontSize={{xs: '18px', lg: '20px'}}
                                    fontWeight={'bold'}
                                    color={'#fff'}
                                >
                                    {t('landing.schools-landing.tokaev-fio')}
                                </Typography>
                                <Typography
                                    lineHeight={'98%'}
                                    fontSize={'13px'}
                                    color={'#fff'}
                                    alignSelf={'flex-end'}
                                >
                                    {t('landing.schools-landing.president')}
                                </Typography>
                            </Stack>

                            <img
                                src={'/images/new-design/tokaev.png'}
                                alt={'Токаев'}
                                style={{
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '50%'
                                }}
                            />
                        </Stack>
                    </Stack>
                )}
            </Stack>

            <Stack
                flexDirection={{xs: 'column', lg: 'row'}}
                justifyContent={'center'}
                sx={{
                    mt: {xs: '42px', lg: '61px'},
                    px: {xs: '20px', lg: '50px'}
                }}
                gap={'20px'}
            >
                <Stack
                    flexDirection={'column'}
                    sx={{
                        width: {xs: 'auto', lg: '420px'},
                        height: {xs: 'auto', lg: '230px'},
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        p: '30px'
                    }}
                    gap={{xs: '40px', lg: '0'}}
                    justifyContent={'space-between'}
                >
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'24px'}
                    >
                        <svg
                            width="41"
                            height="32"
                            viewBox="0 0 41 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M21.513 20.7921C20.8318 21.4739 20.8313 22.5838 21.513 23.2684C21.8431 23.5986 22.2822 23.7805 22.75 23.7805C23.2177 23.7805 23.6562 23.5991 23.9881 23.2684L30.3533 16.9049C30.5114 16.7434 30.6367 16.5551 30.7306 16.3316C30.817 16.1176 30.8612 15.8959 30.8612 15.6702C30.8612 15.4445 30.8175 15.2229 30.7247 14.9931C30.6343 14.7802 30.5078 14.5918 30.3479 14.4326L23.988 8.07268C23.6579 7.74199 23.2181 7.56003 22.7499 7.56003C22.2815 7.56003 21.8418 7.74253 21.5129 8.07268C20.8305 8.75507 20.8305 9.86551 21.5129 10.5478L24.8851 13.92H1.74962C0.785007 13.9201 0 14.7057 0 15.6698C0 16.6339 0.785007 17.4194 1.74962 17.4194H24.8851L21.513 20.7921Z"
                                fill="#1E9AF3"
                            />
                            <path
                                d="M34.6289 0H14.953C11.736 0 9.11968 2.61633 9.11968 5.83217L9.12322 12.0189H9.4731C9.79327 12.4464 10.303 12.7252 10.8705 12.7252C11.4549 12.7252 11.9675 12.4459 12.2842 12.0102L12.6206 12.0055V5.83206C12.6206 4.54727 13.6669 3.50095 14.9529 3.50095H34.6289C35.9143 3.50095 36.9612 4.54727 36.9612 5.83206V25.5085C36.9612 26.7944 35.9143 27.8396 34.6289 27.8396H14.953C13.6671 27.8396 12.6207 26.7944 12.6207 25.5085L12.6178 19.3188H12.2668C11.9466 18.8919 11.4368 18.6143 10.8699 18.6143C10.299 18.6143 9.78631 18.896 9.46677 19.3288L9.11914 19.334V25.5085C9.11914 28.7238 11.7355 31.34 14.9525 31.34H34.6289C37.8453 31.34 40.4611 28.7237 40.4611 25.5085V5.83206C40.4611 2.61622 37.8453 0 34.6289 0Z"
                                fill="#1E9AF3"
                            />
                        </svg>
                        <Typography
                            fontSize={{xs: '16px', lg: '19px'}}
                            fontWeight={'bold'}
                            color={'#05304F'}
                        >
                            {t('landing.schools-landing.as-user')}
                        </Typography>
                    </Stack>

                    <Stack>
                        <Button
                            variant={'contained'}
                            color={'secondary'}
                            onClick={() => {
                                navigate('/auth');
                            }}
                        >
                            {t('landing.schools-landing.auth')}
                        </Button>
                    </Stack>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        width: {xs: 'auto', lg: '420px'},
                        height: {xs: 'auto', lg: '230px'},
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        p: '30px'
                    }}
                    gap={{xs: '40px', lg: '0'}}
                    justifyContent={'space-between'}
                >
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'24px'}
                    >
                        <svg
                            width="36"
                            height="39"
                            viewBox="0 0 36 39"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M7.30303 25.6251H9.07071M7.30303 30.8751H9.07071M26.7475 30.8751H28.5152M26.7475 25.6251H28.5152M14.3737 37V30.0001C14.3737 28.067 15.9565 26.5001 17.9091 26.5001C19.8617 26.5001 21.4444 28.067 21.4444 30.0001V37M17.9091 7.24995H26.4646C26.5636 7.24995 26.6131 7.24995 26.651 7.23087C26.6842 7.21409 26.7112 7.18733 26.7282 7.1544C26.7475 7.11697 26.7475 7.06797 26.7475 6.96995V2.28C26.7475 2.18198 26.7475 2.13298 26.7282 2.09555C26.7112 2.06261 26.6842 2.03586 26.651 2.01907C26.6131 2 26.5636 2 26.4646 2H18.1919C18.0929 2 18.0434 2 18.0056 2.01907C17.9724 2.03586 17.9453 2.06261 17.9284 2.09555C17.9091 2.13298 17.9091 2.18198 17.9091 2.28V7.24995ZM17.9091 7.24995V11.959M17.9091 11.959C18.2533 11.959 18.5976 12.0088 18.9306 12.1083C19.6868 12.3343 20.3557 12.9301 21.6932 14.1218L26.7475 18.6252L29.5335 19.3147C31.0656 19.6939 31.8315 19.8834 32.4019 20.3062C32.905 20.6793 33.2987 21.1784 33.5422 21.7522C33.8182 22.4025 33.8182 23.1841 33.8182 24.7475V31.4001C33.8182 33.3602 33.8182 34.3404 33.4328 35.089C33.094 35.7475 32.5531 36.283 31.8879 36.6185C31.1317 37 30.1416 37 28.1616 37H7.65657C5.67659 37 4.68659 37 3.93034 36.6185C3.26511 36.283 2.72427 35.7475 2.38534 35.089C2 34.3404 2 33.3602 2 31.4001V24.7475C2 23.1841 2 22.4025 2.27599 21.7522C2.51948 21.1784 2.91313 20.6793 3.41633 20.3062C3.98667 19.8834 4.75266 19.6939 6.28464 19.3147L9.07071 18.6252L14.125 14.1218C15.4624 12.9301 16.1313 12.3343 16.8876 12.1083C17.2206 12.0088 17.5649 11.959 17.9091 11.959ZM17.9621 19.9373H17.9754M17.9621 21.2498C17.2299 21.2498 16.6364 20.6622 16.6364 19.9373C16.6364 19.2125 17.2299 18.6248 17.9621 18.6248C18.6943 18.6248 19.2879 19.2125 19.2879 19.9373C19.2879 20.6622 18.6943 21.2498 17.9621 21.2498Z"
                                stroke="#1E9AF3"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <Typography
                            fontSize={{xs: '16px', lg: '19px'}}
                            fontWeight={'bold'}
                            color={'#05304F'}
                        >
                            {t('landing.schools-landing.as-school')}
                        </Typography>
                    </Stack>

                    <Stack>
                        <Button
                            variant={'outlined'}
                            onClick={() => {
                                navigate('/register');
                            }}
                        >
                            {t('landing.schools-landing.register')}
                        </Button>
                    </Stack>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        width: {xs: 'auto', lg: '420px'},
                        height: {xs: 'auto', lg: '230px'},
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        p: '30px'
                    }}
                    gap={{xs: '40px', lg: '0'}}
                    justifyContent={'space-between'}
                >
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        gap={'24px'}
                    >
                        <svg
                            width="41"
                            height="27"
                            viewBox="0 0 41 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M39.5625 13.3982V18.4662C40.2683 18.9909 40.713 20.0151 40.7104 21.1416C40.7104 22.6321 40.7104 24.1758 38.5154 24.1758C36.3216 24.1758 36.3216 22.6335 36.3216 21.1416C36.3216 20.0138 36.7702 18.9909 37.4747 18.4662V14.4703L32.7175 16.2902L32.7124 20.4429C32.7124 20.4429 32.6141 23.0227 30.2458 24.6062C27.8776 26.1897 24.1183 27 20.4302 27C16.7485 27 12.9932 26.1923 10.6223 24.6195C8.25145 23.0466 8.14803 20.504 8.14803 20.504L8.14157 16.2543L2.66559 14.1408C1.07296 13.5271 0 11.9383 0 10.1874C0 8.43786 1.07167 6.84773 2.66559 6.23267L17.3044 0.581531C19.3029 -0.190287 21.5342 -0.194272 23.5366 0.572232L38.3254 6.23001C39.9244 6.84109 41 8.43255 41 10.1887C41 11.4335 40.479 12.5932 39.5625 13.3982ZM11.3436 17.4911V20.7032C11.3436 21.7314 14.8831 23.7002 20.4328 23.7002C25.9824 23.7002 29.5206 21.7314 29.5206 20.7032V17.515L23.5379 19.8039C21.5355 20.5677 19.3016 20.5664 17.3044 19.7933L11.3436 17.4911ZM21.9504 16.3991L34.2921 11.6779C36.3139 10.9101 36.7237 10.4464 36.7237 10.1874C36.7237 9.55409 35.3496 9.07085 34.6708 8.8321L34.6527 8.82576C34.517 8.77927 34.3955 8.73543 34.2946 8.6969L21.9504 3.97434C21.4618 3.78836 20.9525 3.69404 20.4302 3.69404C19.9053 3.69404 19.3908 3.78836 18.8983 3.97965L6.67691 8.69823C6.56186 8.74207 6.42095 8.79122 6.26195 8.84436C5.51863 9.09543 4.27374 9.51654 4.27374 10.1874C4.27374 10.8463 5.57939 11.2953 6.28005 11.5344C6.4313 11.5862 6.56833 11.6354 6.67691 11.6766L18.8996 16.3952C19.8782 16.7724 20.9731 16.7751 21.9504 16.3991Z"
                                fill="#1E9AF3"
                            />
                        </svg>

                        <Typography
                            fontSize={{xs: '16px', lg: '19px'}}
                            fontWeight={'bold'}
                            color={'#05304F'}
                        >
                            {t('landing.schools-landing.as-student')}
                        </Typography>
                    </Stack>

                    <Stack>
                        <Button
                            variant={'outlined'}
                            onClick={() => {
                                navigate('/register/student');
                            }}
                        >
                            {t('landing.schools-landing.register')}
                        </Button>
                    </Stack>
                </Stack>

                {isMobile && (
                    <Stack
                        flexDirection={'column'}
                    >
                        <Stack
                            flexDirection={'row'}
                            sx={{
                                // backgroundColor: 'rgba(255, 255, 255, 0.14)',
                                width: {xs: 'auto', lg: '464px'},
                                height: {xs: 'auto', lg: '220px'},
                                borderRadius: '16px',
                                zIndex: 4,
                                p: '30px',
                                mt: {xs: '16px', lg: '0px'},
                                position: 'relative'
                            }}
                        >
                            <img
                                src={'/images/new-design/cloud.png'}
                                alt={''}
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    left: 0,
                                    userSelect: 'none',
                                    zIndex: -1
                                }}
                            />
                            <Stack
                                flexDirection={'column'}
                                sx={{
                                    mr: {xs: '16px', lg: '42px'},
                                }}
                            >
                                <img
                                    src={'/images/new-design/quotation.png'}
                                    alt={'Цитата'}
                                    style={{
                                        width: isMobile ? '25px' : '38px',
                                        height: isMobile ? '20px' : '32px',
                                    }}
                                />
                            </Stack>

                            <Stack
                                flexDirection={'column'}
                                sx={{
                                    width: {xs: 'auto', lg: '303px'},
                                    height: {xs: 'auto', lg: '140px'}
                                }}
                            >
                                <Typography
                                    fontSize={{xs: '13px', lg: '17px'}}
                                    color={'#fff'}
                                    lineHeight={{xs: '128%', lg: '117%'}}
                                >
                                    {t('landing.schools-landing.tokaev-message')}
                                </Typography>
                            </Stack>
                        </Stack>

                        <Stack
                            flexDirection={'row'}
                            sx={{
                                mt: '20px'
                            }}
                            alignSelf={'flex-end'}
                            gap={'13px'}
                        >
                            <Stack
                                flexDirection={'column'}
                                justifyContent={'center'}
                                gap={'7px'}
                            >
                                <Typography
                                    lineHeight={'98%'}
                                    fontSize={{xs: '18px', lg: '20px'}}
                                    fontWeight={'bold'}
                                    color={'#fff'}
                                >
                                    {t('landing.schools-landing.tokaev-fio')}
                                </Typography>
                                <Typography
                                    lineHeight={'98%'}
                                    fontSize={'13px'}
                                    color={'#fff'}
                                    alignSelf={'flex-end'}
                                >
                                    {t('landing.schools-landing.president')}
                                </Typography>
                            </Stack>

                            <img
                                src={'/images/new-design/tokaev.png'}
                                alt={'Токаев'}
                                style={{
                                    width: '60px',
                                    height: '60px',
                                    borderRadius: '50%'
                                }}
                            />
                        </Stack>
                    </Stack>
                )}
            </Stack>

            <Stack
                sx={{
                    position: 'absolute',
                    bottom: '-61px',
                    left: 0,
                    backgroundImage: `url(/images/new-design/uzor.png)`,
                    width: '100%',
                    height: '122px'
                }}
            />
        </Box>
    );
}