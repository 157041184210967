import type {GridColDef, GridRowParams} from '@mui/x-data-grid';
import {Link} from 'react-router-dom';
import {Avatar, CircularProgress, type Theme} from '@mui/material';
import {DirectionsCar} from '@mui/icons-material';
import {getName} from '@/global/helpers/text';
import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import {SchoolCard} from '@/modules/schools/components/SchoolCard';
import {ServerDataGrid} from '@/global/components/ServerDataGrid';
import {apiGetSchoolList} from '@/modules/schools/services/school.service';
import type {SchoolWithUserDto} from '@/global/api/gen/ts/SchoolWithUserDto';

interface Props {
	onRowClick: (id:number| GridRowParams) => void;
	filter:string;
}

export function AdminSchoolTable({onRowClick, filter}:Props) {
	const { t } = useTranslation();
	
	const isMobile = useMediaQuery((theme:Theme) => theme.breakpoints.down('md'));

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 25, page: 0
	});

	const {data, isLoading} = apiGetSchoolList({
		offset: paginationModel.page * paginationModel.pageSize,
		limit: paginationModel.pageSize,
		q: filter
	});

	const columns: GridColDef<SchoolWithUserDto>[] = useMemo(() => [
		{
			field: 'empty',
			headerName: '',
			headerClassName: 'datagrid--header',
			maxWidth: 10,
			width: 10,
			sortable: false,
		},
		{
			field: 'id',
			headerName: 'ID',
			headerClassName: 'datagrid--header',
			width: 90,
			sortable: true,
		},
		{
			field: 'bin',
			headerName: t('register.school_list_component.grid.bin'),
			headerClassName: 'datagrid--header',
			width: 200,
			sortable: false,
		},
		{
			field: 'title',
			headerName: t('register.school_list_component.grid.title'),
			headerClassName: 'datagrid--header',
			minWidth: 400,
			width: 500,
			sortable: false,
			flex: 1,
			renderCell: (params) => {
				return (
					<Link
						to={`/schools/${params.row.id}`}
						style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Avatar
							sx={{color: '#1E9AF3', bgcolor: '#fff', mr: 2}}
						>
							<DirectionsCar fontSize="small"/>
						</Avatar>
						{params.value}
					</Link>
				);
			}
		},
		{
			field: 'user',
			headerName: t('register.school_list_component.grid.user'),
			headerClassName: 'datagrid--header',
			minWidth: 400,
			renderCell: ({row}) => {
				const name = getName(row.manager ?? {});
				return (
					<Link
						to={'/user/' + row.manager?.id}
						target={'_blank'}
						style={{textDecoration: 'none', color: 'black', display: 'flex', flexDirection: 'row', alignItems: 'center'}}
					>
						<Avatar
							sx={{mr: 1.5, fontSize: 16, color: '#1E9AF3', bgcolor: '#fff'}}
						>
							{name.partialName}
						</Avatar>
						{name.fullName}
						&nbsp;#
						{row.manager?.id}
					</Link>
				);
			}
		}
	], []);

	if (isMobile) {
		return (
			<>
				{(isLoading) && <CircularProgress />}
				{
					data && data.items.map((row)=> (
						<SchoolCard
							onRowClick={onRowClick}
							key={row.id}
							school={row}
						/>
					))
				}
			</>
		);
	}

	return (
		<ServerDataGrid
				paginationModel={paginationModel}
				onPaginationModelChange={setPaginationModel}
				rows={data?.items ?? []}
				onRowClick={onRowClick}
				columns={columns}
				loading={isLoading}
				rowCount={data?.total ?? 0}
				disableRowSelectionOnClick
				disableColumnMenu={true}
				disableColumnFilter={true}
		/>
	);
}