import React, {Fragment} from 'react';
import {Avatar, Box, Button, Paper, Stack, type SxProps, type Theme, Tooltip, Typography} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {DirectionsCar} from '@mui/icons-material';
import {useTranslation} from 'react-i18next';
import {useUserStore} from '@/global/store/UserStore';
import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import {useModal} from "@/global/providers/ModalProvider";
import type {GroupTransportDto} from "@/global/api/gen/ts/GroupTransportDto";
import {apiDetachTransportFromGroup} from "@/modules/groups/services/group.service";
import {TransportsModal} from "@/modules/employees/components/TransportsModal";

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface TransportWindowProps {
    groupInfo: GroupDataDto;
}

function TransportWindow({groupInfo}: TransportWindowProps) {

    const {t} = useTranslation();

    const userStore = useUserStore((state) => state);

    const {openModal} = useModal();

    const openTransportModal = () => {
        openModal(TransportsModal, {groupInfo}, `transport_modal_${groupInfo.id}`);
    };

    const {mutate: detachTransportFromGroup} = apiDetachTransportFromGroup();

    const handleDetach = (transport: GroupTransportDto) => {
        const transportDto = {
            car_id: transport.car_id,
            group_id: groupInfo.id
        };

        detachTransportFromGroup(transportDto);
    };

    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>
                <Typography variant="h4">
                    {t('school_page.group_page.attributes_window.window.this_group.transport')}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={{mb: 1.5}}
                    color="gray"
                >
                    {t('school_page.group_page.attributes_window.window.this_group.transport')}
                </Typography>
                <Stack
                    flexDirection={'column'}
                    gap={'16px'}
                >
                    {groupInfo.transport?.length === 0 && (
                        <Typography
                            fontSize={'14px'}
                            color={"gray"}
                        >
                            {t('school_page.group_page.attributes_window.window.no_data')}
                        </Typography>
                    )}
                    {groupInfo.transport && groupInfo.transport.length > 0 && (
                        groupInfo.transport.map((transport) => {
                            return (
                                <Fragment key={transport.id}>
                                    <Stack
                                        sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}}
                                        flexDirection="row"
                                        alignItems={'center'}
                                    >
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            <DirectionsCar/>
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack sx={{width: '100%'}}>
                                                <Stack
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    justifyContent="start"
                                                    gap={1}
                                                    sx={{width: '100%'}}
                                                >
                                                    <Typography>
                                                        {transport.car.model}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="gray"
                                                    >
                                                        {transport.car.plate_number}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                        {groupInfo.status === 1 && (
                                            <Tooltip title={'Открепить'}>
                                                <ClearIcon
                                                    sx={[
                                                        {ml: 5},
                                                        {
                                                            '&:hover': {
                                                                color: 'red',
                                                                cursor: 'pointer'
                                                            },
                                                        },
                                                    ]}
                                                    onClick={() => handleDetach(transport)}
                                                />
                                            </Tooltip>
                                        )}
                                    </Stack>
                                </Fragment>
                            );
                        })
                    )}
                    <Button
                        onClick={openTransportModal}
                        sx={{
                            width: 'fit-content',
                            ml: '16px'
                        }}
                        variant={'contained'}
                        disabled={userStore.isObserver}
                    >
                        {t('school_page.group_page.attributes_window.modal.add_btn.transport')}
                    </Button>
                </Stack>
            </Paper>
        </Box>
    );
}

export default TransportWindow;