import type {GroupDataDto} from "@/global/api/gen/ts/GroupDataDto";
import type {ModalSystemProps} from "@/global/providers/ModalProvider";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useSchoolStore} from "@/School/store/SchoolStore";
import {useSnackbarStore} from "@/global/store/SnackbarStore";
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
    Typography
} from "@mui/material";
import {DirectionsCar} from "@mui/icons-material";
import {apiAttachTransportToGroup} from "@/modules/groups/services/group.service";
import {apiGetTransportListBySchool} from "@/modules/transports/services/transport.service";

interface TransportModalProps {
    groupInfo: GroupDataDto;
}

export function TransportsModal({groupInfo, open, close}: ModalSystemProps<TransportModalProps>) {

    const {t} = useTranslation();

    const [transportId, setTransportId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    const {mutate: attachTransportToGroup} = apiAttachTransportToGroup();

    const {data: transportList} = apiGetTransportListBySchool(+(schoolInfo.id ?? 0), {
        limit: 100,
        offset: 0
    });

    const handleSelectChange = (e: SelectChangeEvent) => {
        setTransportId(e.target.value);
    };

    const handleAttach = () => {

        if (!groupInfo.id || !transportId) {
            snackbar.errorMessage(t('school_page.group_page.attributes_window.modal.required.transport'));
            return;
        }

        const transportDto = {
            group_id: groupInfo.id,
            car_id: +(transportId)
        };

        attachTransportToGroup(transportDto);
    };

    return (
        <Dialog
            open={open}
            onClose={() => close(0)}
        >
            <DialogTitle>
                {t('school_page.group_page.attributes_window.modal.add_dialog.transport')}
                {' '}
                <i>
                    <b>
                        {groupInfo.title}
                    </b>
                </i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={transportId}
                    >
                        {transportList?.items.map((transport) => {
                            return (
                                <MenuItem
                                    value={transport.id}
                                    key={transport.id}
                                >
                                    <Stack
                                        sx={{width: '100%'}}
                                        flexDirection="row"
                                        alignItems={'center'}
                                    >
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            <DirectionsCar/>
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent="start"
                                                gap={1}
                                                sx={{width: '100%'}}
                                            >
                                                <Typography>
                                                    {transport.model}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="gray"
                                                >
                                                    {transport.plate_number}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Typography
                        variant="body2"
                        sx={{mt: 1}}
                    >
                        {t('school_page.group_page.attributes_window.modal.reminder.transport')}
                    </Typography>

                    <Button onClick={handleAttach}>
                        {t('school_page.group_page.attributes_window.modal.add_dialog.transport')}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}