import {useCustomQuery} from "@/global/hooks/useCustomQuery";
import {employeesService} from "@/global/api/gen";
import type {
    EmployeeControllerGetEmployeeInfoQueryParams
} from "@/global/api/gen/ts/employeesDTO/EmployeeControllerGetEmployeeInfo";
import {EmployeesKeys} from "@/modules/employees/services/employees.keys";
import {useCustomMutation} from "@/global/hooks/useCustomMutation";
import type {EmployeeChangeBodyDto} from "@/global/api/gen/ts/EmployeeChangeBodyDto";
import type {
    EmployeeControllerGetAllSchoolTeachersQueryParams
} from "@/global/api/gen/ts/employeesDTO/EmployeeControllerGetAllSchoolTeachers";
import type {EmployeeCreateDto} from "@/global/api/gen/ts/EmployeeCreateDto";
import type {OmmitedUseQueryOptions} from "@/global/types";
import type {EmployeeDtoSearchResponseDto} from "@/global/api/gen/ts/EmployeeDtoSearchResponseDto";
import type {
    EmployeeControllerGetActiveSchoolTeachersQueryParams
} from "@/global/api/gen/ts/employeesDTO/EmployeeControllerGetActiveSchoolTeachers";

export const apiGetEmployeeInfo = (id: number, params: EmployeeControllerGetEmployeeInfoQueryParams) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => employeesService.employeeControllerGetEmployeeInfo({employeeId:id}, params)
            .then((res) => res.data),
        queryKey: EmployeesKeys.detailWithParams(id, params)
    });
};

export const apiChangeEmployeeInfo = () => {
    return useCustomMutation({
        mutationFn: (data: EmployeeChangeBodyDto) => employeesService.employeeControllerChangeEmployeeInfo(data)
            .then((res) => res.data),
        queryKey: (data) => EmployeesKeys.detail(data.id),
    });
};

export const apiGetTeacherListBySchool = (id: number, params: EmployeeControllerGetAllSchoolTeachersQueryParams, option?: OmmitedUseQueryOptions<EmployeeDtoSearchResponseDto>) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => employeesService.employeeControllerGetAllSchoolTeachers({id}, params)
            .then((res) => res.data),
        queryKey: EmployeesKeys.searchTeachers(id, params),
        ...option,
    });
};

export const apiGetDriverListBySchool = (id: number, params: EmployeeControllerGetAllSchoolTeachersQueryParams, option?: OmmitedUseQueryOptions<EmployeeDtoSearchResponseDto>) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => employeesService.employeeControllerGetAllSchoolDrivers({id}, params)
            .then((res) => res.data),
        queryKey: EmployeesKeys.searchDrivers(id, params),
        ...option
    });
};

export const apiGetMasterListBySchool = (id: number, params: EmployeeControllerGetAllSchoolTeachersQueryParams, option?: OmmitedUseQueryOptions<EmployeeDtoSearchResponseDto>) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => employeesService.employeeControllerGetAllSchoolMasters({id}, params)
            .then((res) => res.data),
        queryKey: EmployeesKeys.searchMasters(id, params),
        ...option
    });
};

export const apiAddTeacherToSchool = () => {
    return useCustomMutation({
        mutationFn: (data: EmployeeCreateDto) => employeesService.employeeControllerCreateTeacher(data)
            .then((res) => res.data),
        queryKey: (data) => EmployeesKeys.teachers(data.employee.school_id),
    });
};

export const apiAddDriverToSchool = () => {
    return useCustomMutation({
        mutationFn: (data: EmployeeCreateDto) => employeesService.employeeControllerCreateDriver(data)
            .then((res) => res.data),
        queryKey: (data) => EmployeesKeys.drivers(data.employee.school_id),
    });
};

export const apiAddMasterToSchool = () => {
    return useCustomMutation({
        mutationFn: (data: EmployeeCreateDto) => employeesService.employeeControllerCreateMaster(data)
            .then((res) => res.data),
        queryKey: (data) => EmployeesKeys.masters(data.employee.school_id),
    });
};

export const apiSearchActiveTeachers = (id:number, params:EmployeeControllerGetActiveSchoolTeachersQueryParams) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => employeesService.employeeControllerGetActiveSchoolTeachers({id}, params)
            .then(res => res.data),
        queryKey: EmployeesKeys.searchActiveTeachers(id, params)
    });
};

export const apiSearchActiveMasters = (id:number, params:EmployeeControllerGetActiveSchoolTeachersQueryParams) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => employeesService.employeeControllerGetActiveSchoolMasters({id}, params)
            .then(res => res.data),
        queryKey: EmployeesKeys.searchActiveMasters(id, params)
    });
};

export const apiSearchActiveDrivers = (id:number, params:EmployeeControllerGetActiveSchoolTeachersQueryParams) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => employeesService.employeeControllerGetActiveSchoolDrivers({id}, params)
            .then(res => res.data),
        queryKey: EmployeesKeys.searchActiveDrivers(id, params)
    });
};