import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ru from './global/translation/ru.json';
import kz from './global/translation/kz.json';

i18n.use(initReactI18next)
    .init({
        resources: {
            ru: {
                translation: ru
            },
            kz: {
              translation: kz
            }
        },
        lng: 'ru', // начальный язык
        fallbackLng: 'ru', // используйте 'en' если язык не поддерживается
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;