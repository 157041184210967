import {useCustomQuery} from '@/global/hooks/useCustomQuery';
import {groupsService} from '@/global/api/gen';
import type {
    GroupsControllerGetGroupsBySchoolIdQueryParams
} from '@/global/api/gen/ts/GroupsDTO/GroupsControllerGetGroupsBySchoolId';
import {GroupKeys} from '@/modules/groups/services/group.keys';
import {useCustomMutation} from '@/global/hooks/useCustomMutation';
import type {
    GroupsControllerCreateGroupMutationRequest
} from '@/global/api/gen/ts/GroupsDTO/GroupsControllerCreateGroup';
import type {
    GroupsControllerGetPracticeHoursInfoByGroupIdQueryParams
} from "@/global/api/gen/ts/GroupsDTO/GroupsControllerGetPracticeHoursInfoByGroupId";
import type {GroupAutoFillClassesDto} from "@/global/api/gen/ts/GroupAutoFillClassesDto";
import type {
    GroupsControllerGetSectionsInfoByGroupIdQueryParams
} from "@/global/api/gen/ts/GroupsDTO/GroupsControllerGetSectionsInfoByGroupId";
import type {
    GroupsControllerGetGroupsWithStatusExamsByUserIdQueryParams
} from "@/global/api/gen/ts/GroupsDTO/GroupsControllerGetGroupsWithStatusExamsByUserId";
import type {GroupChangeStatusDto} from "@/global/api/gen/ts/GroupChangeStatusDto";
import type {GroupAttachTeacherDto} from "@/global/api/gen/ts/GroupAttachTeacherDto";
import type {GroupAttachDriverDto} from "@/global/api/gen/ts/GroupAttachDriverDto";
import type {GroupAttachMasterDto} from "@/global/api/gen/ts/GroupAttachMasterDto";
import type {GroupAttachTransportDto} from "@/global/api/gen/ts/GroupAttachTransportDto";
import type {
    GroupsControllerGetGroupClassesQueryParams
} from "@/global/api/gen/ts/GroupsDTO/GroupsControllerGetGroupClasses";
import type {GroupClassDeleteDto} from "@/global/api/gen/ts/GroupClassDeleteDto";
import type {GroupClassSubmitDto} from "@/global/api/gen/ts/GroupClassSubmitDto";
import type {GroupChangeDatesDto} from "@/global/api/gen/ts/GroupChangeDatesDto";
import type {GroupChangeTitleDto} from "@/global/api/gen/ts/GroupChangeTitleDto";
import type {GroupChangeCategoryDto} from "@/global/api/gen/ts/GroupChangeCategoryDto";

export const apiGetGroupList = (id: number, params: GroupsControllerGetGroupsBySchoolIdQueryParams) => {
    return useCustomQuery({
        queryFn: () => groupsService.groupsControllerGetGroupsBySchoolId({id}, params)
            .then((res) => res.data),
        queryKey: GroupKeys.schoolPublic(id, params)
    });
};

export const apiCreateGroup = () => {
    return useCustomMutation({
        mutationFn: (data: GroupsControllerCreateGroupMutationRequest) => groupsService.groupsControllerCreateGroup(data)
            .then((res) => res.data),
        queryKey: (data) => GroupKeys.searchBySchool(data.group.school_id)
    });
};

export const apiGetPracticeHours = (groupId: number, params: GroupsControllerGetPracticeHoursInfoByGroupIdQueryParams) => {
    return useCustomQuery({
        queryFn: () => groupsService.groupsControllerGetPracticeHoursInfoByGroupId({groupId}, params)
            .then((res) => res.data),
        queryKey: GroupKeys.practiceHours(groupId, params)
    });
};

export const apiGetTheoryHours = (groupId: number, params: GroupsControllerGetSectionsInfoByGroupIdQueryParams) => {
    return useCustomQuery({
        queryFn: () => groupsService.groupsControllerGetSectionsInfoByGroupId({groupId}, params)
            .then((res) => res.data),
        queryKey: GroupKeys.theoryHours(groupId, params)
    });
};

export const apiGroupAutoSubmit = () => {
    return useCustomMutation({
        mutationFn: (data: GroupAutoFillClassesDto) => groupsService.groupsControllerSoapAutoSubmitGroupClass(data)
            .then((res) => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiGetGroupsExam = (params: GroupsControllerGetGroupsWithStatusExamsByUserIdQueryParams) => {
    return useCustomQuery({
        queryFn: () => groupsService.groupsControllerGetGroupsWithStatusExamsByUserId(params)
            .then((res) => res.data),
        queryKey: GroupKeys.groupExams(params)
    });
};

export const apiGroup = (id: number) => {
    return useCustomQuery({
        enabled: !!id && !isNaN(id),
        queryFn: () => groupsService.groupsControllerGetGroupById({id})
            .then(res => res.data),
        queryKey: GroupKeys.detail(id)
    });
};

export const apiChangeStatusGroup = () => {
    return useCustomMutation({
        mutationFn: (data: GroupChangeStatusDto) => groupsService.groupsControllerChangeGroupStatus(data)
            .then((res) => res.data),
        queryKey: (data) => GroupKeys.detail(data.id)
    });
};

export const apiAttachTeacherToGroup = () => {
    return useCustomMutation({
        mutationFn: (data: GroupAttachTeacherDto) => groupsService.groupsControllerAttachTeacherToGroup(data)
            .then((res) => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiAttachDriverToGroup = () => {
    return useCustomMutation({
        mutationFn: (data: GroupAttachDriverDto) => groupsService.groupsControllerAttachDriverToGroup(data)
            .then((res) => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiAttachMasterToGroup = () => {
    return useCustomMutation({
        mutationFn: (data: GroupAttachMasterDto) => groupsService.groupsControllerAttachMasterToGroup(data)
            .then((res) => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiAttachTransportToGroup = () => {
    return useCustomMutation({
        mutationFn: (data: GroupAttachTransportDto) => groupsService.groupsControllerAttachTransportToGroup(data)
            .then((res) => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiDetachTransportFromGroup = () => {
    return useCustomMutation({
        mutationFn: (data: GroupAttachTransportDto) => groupsService.groupsControllerDetachTransportToGroup(data)
            .then((res) => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiSearchGroupClasses = (groupId: number, params: GroupsControllerGetGroupClassesQueryParams) => {
    return useCustomQuery({
        enabled: !!groupId && !isNaN(groupId),
        queryFn: () => groupsService.groupsControllerGetGroupClasses({groupId}, params)
            .then(res => res.data),
        queryKey: GroupKeys.classesSearch(groupId, params)
    });
};

export const apiDeleteClass = () => {
    return useCustomMutation({
        mutationFn: (data: GroupClassDeleteDto) => groupsService.groupsControllerSoapDeleteGroupClass(data)
            .then(res => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiSubmitClass = () => {
    return useCustomMutation({
        mutationFn: (data: GroupClassSubmitDto) => groupsService.groupsControllerSoapSubmitGroupClass(data)
            .then(res => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiDeleteGroup = () => {
    return useCustomMutation({
        mutationFn: (data: { id: number }) => groupsService.groupsControllerDeleteGroupById(data)
            .then(res => res.data),
        queryKey: GroupKeys.all
    });
};

export const apiGroupChangeDates = () => {
    return useCustomMutation({
        mutationFn: (data: GroupChangeDatesDto) => groupsService.groupsControllerChangeGroupDates(data)
            .then(res => res.data),
        queryKey: (data) => GroupKeys.detail(data.id)
    });
};

export const apiGroupChangeTitle = () => {
    return useCustomMutation({
        mutationFn: (data: GroupChangeTitleDto) => groupsService.groupsControllerChangeGroupTitle(data)
            .then(res => res.data),
        queryKey: (data) => GroupKeys.detail(data.id)
    });
};

export const apiGroupChangeCategory = () => {
    return useCustomMutation({
        mutationFn: (data: GroupChangeCategoryDto) => groupsService.groupsControllerChangeGroupCategory(data)
            .then(res => res.data),
        queryKey: (data) => GroupKeys.detail(data.id)
    });
};

